<template>
    <div class="container footer mt-5">
        <div class="row footer-wrapper">
            <div class="col-12 col-lg-2">
                <p class="my-1">
                    <a href="tel:+74994017045">+7 (499) 401-70-45</a><br><a href="mailto:support@telegynecology.ru">support@telegynecology.ru</a>
                </p>
            </div>
            <div class="col-12 col-lg-2">
                <p class="text-center"><img src="/images/logo_tele.svg" style="height: 60px;"/></p>
            </div>
            <div class="col-12 col-lg-2">
                <p class="text-center"><a href="https://www.52gkb.ru/otdelenia/agf/zk-6?ysclid=ll5bbdt5ks596374484">
                    <img height="50px" src="https://telegynecology.ru/images/support1.png"
                         alt="Городская клиническая больница №52"></a></p>
            </div>
            <div class="col-12 col-lg-2">
                <p class="text-center"><img class="my-2" height="30px" src="https://telegynecology.ru/images/support2.png"
                                            alt="Московский центр инновационных технологий в здравоохранении"></p>
            </div>
            <div class="col-12 col-lg-2">
                <p class="text-center"><img height="40px" src="https://telegynecology.ru/images/support3.png"
                                            alt="Комплекс социального развития Москвы"></p>
            </div>
            <div class="col-12 col-lg-2">
                <p class="text-center"><img height="40px" src="https://telegynecology.ru/images/support4.png"
                                            alt="Департамент здравоохранения города Москвы"></p>
            </div>
        </div>
        <div class="row">
            <div class="col-12"><p class="text-center" style="font-size: 14px;">©&nbsp;2023,&nbsp;Цифровой центр «Здоровье женщины»</p>
            </div>
        </div>
    </div>
</template>

<style scoped>
.footer {
}

.footer p {
    padding-top: 20px;
}

.footer .title-alt2 {
    font-size: 16px;
    font-style: normal;
    font-weight: bold;
    line-height: 18px;
    padding-bottom: 10px;
}

.footer a {
    font-size: 14px;
    color: #000000;
    text-align: center;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
    text-decoration: none;
}

.footer a:hover {
    color: #ffa500;
    text-decoration: underline;
}

.footer a:active {
    color: #00008b;
    text-decoration: underline;
}

.footer ul {
    list-style-type: none;
    padding-top: 0px;
    margin-top: -10px;
}

.footer .copyright {
    margin-top: 20px;
}

.footer img {
    max-width: 90%;
}

</style>

<script>
export default {
    name: 'FooterComponent',
}
</script>